type Online = 'ONLINE';
type Offline = 'OFFLINE';
type OfflineSync = 'OFFLINE-SYNC';
type OnlineSync = 'ONLINE-SYNC';
type InstallAppSync = 'INSTALL-APP-SYNC';
type UpdateAppSync = "UPDATE-APP-SYNC";
type PartialOnlineSync = "PARTIAL-ONLINE-SYNC";
type PartialOfflineSync = "PARTIAL-OFFLINE-SYNC";
type Truncate = 'TRUNCATE';

export type SyncDefinitionId = OfflineSync | OnlineSync | InstallAppSync | UpdateAppSync | PartialOnlineSync | PartialOfflineSync;


export type AppState = Offline | Online;
export type SyncType = OfflineSync | OnlineSync | Truncate;

export type RecordStatus = 'UNSYNCED' | 'SYNCED' | 'FILE-SYNCED' | 'DRAFT' | 'FILE-DRAFT' | 'CREATED' | 'FILE-CREATED' | 'UPDATED' | 'FILE-UPDATED' | 'DESTROYED' | 'FILE-DESTROYED';

export type TruncateIndexDBObjectStoreMode = "TRUNCATE_BEFORE_OFFLINE_SYNC" | "TRUNCATE_AFTER_ONLINE_RECORD_SYNC" | "TRUNCATE_AFTER_ONLINE_STEP_SYNC" | "TRUNCATE_AFTER_ONLINE_SYNC"